import React from 'react'
import './FlavourRevolution.css'
import agave from '../../../Assets/agave.webp'
import { Link } from 'react-router-dom'

export default function FlavourRevolution() {
  return (
    <div id='flavour-revolution'>

      <img src={agave} id='revolution-agave' alt='Thiago Tequila Flavoured Agave'/>

      <div id='revolution-container'>
        <p id='a-flavour'>A Flavour</p>
        <p id='revolution'>revolution</p>

        <p className='revolution-txt'>· Premium Reposado Tequila crafted in the heart of Los Altos, Jalisco.</p>

        <p className='revolution-txt'>· Authentic natural flavours sourced locally for a true taste of Mexico.</p>
       
        <p className='revolution-txt'>· Aged to perfection with no less than 6-week fermentation—fewer calories and no sugary hangovers.</p>


        <Link className='thiago-btn' to={'/products'}>
          Pre Order Now
        </Link>
      </div>

      <img src={agave} id='revolution-agave-mobile' alt='Thiago Tequila Flavour Revolution'/>

        
    </div>
  )
}
